/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
export default {
  data: function data() {
    return {
      showFlag: false,
      //是否显示组织架构弹窗
      selectType: 0,
      //组织架构选择类型
      infoChecked: [],
      //
      approverChecked: [],
      userInfo: ''
    };
  },
  methods: {
    addUse: function addUse() {},
    selectUsers: function selectUsers(val) {
      this.approver = val; // this.userSerial=val[0].userSerial;

      this.userInfo = {
        userDepname: val[0].userDepname,
        userLname: val[0].userName,
        userTelephone: val[0].userTelephone,
        userSerial: val[0].userSerial
      };
      this.$emit('getSubInfo', this.userInfo); // this.$utils.Store.setItem('leadSerial', val[0].userSerial)
    },
    selectApprovalUser: function selectApprovalUser() {
      var _this = this;

      this.$utils.Tools.loadShow();
      this.$api.Approver.selectApprovalUser({
        userSerial: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.approver = [];
          _this.approver = data;

          _this.$utils.Store.setItem('oldVal', data[0]);
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    chooseApprover: function chooseApprover() {
      this.infoChecked = [];
      this.selectType = 1;
      this.showFlag = true;
    } // ----以上是组织树的内容

  },
  props: ['title']
};