/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Group from 'vux/src/components/group';
import Cell from 'vux/src/components/cell';
import Checker from 'vux/src/components/checker/checker';
import CheckerItem from 'vux/src/components/checker/checker-item';
import XButton from 'vux/src/components/x-button';
import Datetime from 'vux/src/components/datetime';
import atitle from '@/components/vis/Title02';
export default {
  name: 'VisitorDetail',
  components: {
    Cell: Cell,
    Group: Group,
    XButton: XButton,
    Datetime: Datetime,
    Checker: Checker,
    CheckerItem: CheckerItem,
    atitle: atitle
  },
  data: function data() {
    return {
      checkerList: [],
      area: [],
      currDate: '',
      visitor: {},
      //来访信息
      register: {},
      //注册信息
      identity: '',
      busFlag: ['1'],
      isOperate: true,
      xh: '',
      kssj: '',
      jssj: '',
      remark: '',
      curTime: '',
      //当前时间
      btnDis: false //按钮是否可以按

    };
  },
  computed: {
    idDis: function idDis() {
      if (this.register.state === '0' && this.register.visitorState !== '10') {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    goback: function goback() {
      this.$router.go(-1);
    },

    /**
     *  function获取当前时间
     *  @wzx
     *  @2019-04-01
     **/
    getSysDate: function getSysDate() {
      var _this = this;

      this.$utils.Tools.loadShow();
      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.currDate = data.sysDate.substring(0, 10);
          _this.curTime = data.sysDate.substring(0, 16);
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },

    /**
     *  function获取访客基本信息
     *  @wzx
     *  @2019-04-01
     **/
    getVisitorInfo: function getVisitorInfo(xh) {
      var _this2 = this;

      this.$utils.Tools.loadShow();
      this.$api.Visit.selectByPrimaryKey(xh).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.visitor = data.visUserEntity;
          _this2.register = data.visRegisterEntity;
          _this2.kssj = data.visRegisterEntity.visKssj;
          _this2.jssj = data.visRegisterEntity.visJssj;
          _this2.remark = data.visRegisterEntity.remark;

          if (_this2.register.state === '0' && _this2.register.visitorState !== '10') {
            var list = null;

            if (_this2.register.visArea) {
              list = _this2.register.visArea.split(',');
            }

            _this2.selectAllListByEntity(list);
          } else {
            var _list = _this2.register.visAreaName.split(',');

            _list.some(function (item, i) {
              _this2.area.push({
                areaName: item
              });

              _this2.checkerList.push(i);
            });
          }
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },

    /**
     *  function获取访客区域
     *  @lwt
     *  @2020-08-01
     **/
    selectAllListByEntity: function selectAllListByEntity(list) {
      var _this3 = this;

      this.$utils.Tools.loadShow();
      this.$api.Visit.selectAllListByEntity({
        userNo: 'system'
      }).then(function (response) {
        _this3.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          _this3.area = data;

          if (list != null) {
            data.forEach(function (item, i) {
              list.forEach(function (sub) {
                if (item.areaNo == sub) {
                  _this3.checkerList.push(i);
                }
              });
            });
          } else {
            _this3.checkerList.push(0);
          }
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },

    /**
     *  function拒绝来访
     *  @wzx
     *  @2019-04-02
     **/
    refuseClick: function refuseClick() {
      var _this4 = this;

      var num = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      this.$utils.Tools.loadShow();
      this.$api.Visit.updateByPrimaryKey({
        xh: +this.xh,
        visitorState: num == 11 ? '9' : '2',
        approveState: num == 11 ? '11' : '2',
        approvalType: 1
      }).then(function (response) {
        _this4.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg;

        if (+code !== _this4.$code.success) {
          _this4.$utils.Tools.toastShow(msg);
        } else {
          window.location.reload();
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    },

    /**
     *  function同意来访
     *  @wzx
     *  @2019-04-02
     **/
    agreeClick: function agreeClick() {
      var _this5 = this;

      if (this.jssj <= this.kssj) {
        this.$utils.Tools.toastShow('预约结束时间必须大于预约开始时间');
        return;
      }

      this.btnDis = true;
      this.$utils.Tools.loadShow(); //areaNo

      var nolist = [];
      this.checkerList.some(function (item) {
        nolist.push(_this5.area[item].areaNo);
      });
      nolist = nolist.join();
      this.$api.Visit.updateByPrimaryKey({
        xh: +this.xh,
        approveState: '1',
        visKssj: this.kssj,
        visJssj: this.jssj,
        approvalType: 1,
        visArea: nolist
      }).then(function (response) {
        _this5.$utils.Tools.loadHide();

        _this5.btnDis = false;
        var code = response.code,
            msg = response.msg;

        if (+code !== _this5.$code.success) {
          _this5.$utils.Tools.toastShow(msg);
        } else {
          window.location.reload();
        }
      }).catch(function () {
        _this5.$utils.Tools.httpError();
      });
    },
    pushHistory: function pushHistory() {
      var state = {
        title: 'title',
        url: '#'
      };
      window.history.pushState(state, state.title, state.url);
    }
  },
  created: function created() {
    this.xh = this.$route.query.xh || this.$route.params.state;
    this.getSysDate();
    this.getVisitorInfo(this.xh); // this.selectAllListByEntity()
  },
  mounted: function mounted() {
    if (this.$route.params.notification) {
      this.pushHistory();
    }
  },
  destroyed: function destroyed() {}
};